import * as Yup from 'yup'
import { date, email, password, phone, postalCode } from './regexes'

export const emailOptional = Yup.string().matches(email, 'forms.errors.email')

export const emailRequired = emailOptional.required('forms.errors.email')

export const firstNameRequired = Yup.string().required('forms.errors.firstName')

export const lastNameRequired = Yup.string().required('forms.errors.lastName')

export const birthDateOptional = Yup.string().matches(date, 'forms.errors.birthdate')

export const birthDateRequired = birthDateOptional.required('forms.errors.isRequired')

export const phoneOptional = Yup.string().matches(phone, 'forms.errors.phone')

export const phoneRequired = phoneOptional.required('forms.errors.isRequired')

export const passwordOptional = Yup.string()
  .min(8, 'forms.errors.passwordLength')
  .matches(password, 'forms.errors.passwordRegex')

export const passwordRequired = passwordOptional.required('forms.errors.passwordRequired')

export const cityRequired = Yup.string().required('forms.errors.city')

export const streetRequired = Yup.string().required('forms.errors.street')

export const houseNumberRequired = Yup.string().required('forms.errors.houseNumber')

export const countryRequired = Yup.string().required('country')

export const postalCodeRequired = Yup.string()
  .required('forms.errors.postalCode')
  .matches(postalCode, 'forms.errors.postalCodeInvalid')

export const internationalPostalCodeRequired = Yup.string()
  .required('forms.errors.postalCode')
  .min(4, 'forms.errors.postalCodeInvalid')

export const genderRequired = Yup.string().required('forms.errors.salutation')

export const messageRequired = Yup.string().required('forms.errors.message')
