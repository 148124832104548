// @ts-strict-ignore
import { FirstDayOfWeek, useMonth } from '@datepicker-react/hooks'
import { format } from 'date-fns'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'
import { Grid, Text } from '@gassan-ui'
import Day from './Day'

const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 0.5rem;
  position: relative;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    background: ${(p) => p.theme.colors.tabaccoLight};
    width: calc(100% - ${rem(30)});
    height: 1px;
    left: -2px;
    right: 0;
    margin: 0 auto;
  }
`

type Props = {
  prev: () => void
  next: () => void
  year: number
  month: number
  firstDayOfWeek: FirstDayOfWeek
  value: string
  onSetDate: (date) => void
}

const Month: FC<Props> = ({ year, month, firstDayOfWeek, prev, next, value, onSetDate }) => {
  const { t } = useTranslation('forms')
  const { days, weekdayLabels } = useMonth({
    year,
    month,
    firstDayOfWeek,
  })

  return (
    <>
      <Days>
        {weekdayLabels.map((_, index) => (
          <Text variant="micro" mb="0.25rem" color="tabaccoBrown" key={index}>
            {t(`weekDays.${index}`)}
          </Text>
        ))}
      </Days>
      <Grid gridTemplateColumns="repeat(7, 1fr)" justifyItems="center">
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
                isSelected={format(day.date, 'dd-MM-yyyy') === value}
                onSetDate={() => onSetDate(day.date)}
              />
            )
          }
          return <div key={index} />
        })}
      </Grid>
    </>
  )
}
export default Month
